/* eslint-disable prefer-const */
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Grid } from '@material-ui/core'
// import moment from 'moment'
import humps from 'humps'
// Core
import Theme from '@refera/ui-core'

// Icons
import { FilterSearch as FilterIcon, Danger as DangerIcon } from '@refera/ui-icons'

// Components
import { Alert, Button, Datagrid, Dialog, Loader, Toast, useConfirm } from '@refera/ui-web'

// Hooks
import useFetchCall from '_hooks/use-fetch-call'

// Redux
import { useDispatch, useSelector } from 'react-redux'

import useStyles from './styles'

import PaymentsFilter from './components/payments-filter/PaymentsFilter'
import HeaderTitle from '_/components/header-title'
import { TableColumns } from './components/TableColumns'
import {
  getPaymentAnticipationsList,
  GET_PAYMENT_ANTICIPATIONS_LIST,
  getBudgetInstallments,
  GET_BUDGET_INSTALLMENTS,
  getFinanceGenericParameters,
  removeAnticipationsPayments,
  REMOVE_ANTICIPATIONS_PAYMENTS,
} from '_/modules/finance/actions'
import { FINANCE_ROUTES } from '_/utils/constants'
import FloatingMenu from '../components/FloatingMenu'
import { formatArrayToString } from '../manage-installments/utils/constants'
import PaymentConfirmation from '../manage-installments/paymentConfirmation'
import { financeGenericParametersSelector } from '_/modules/finance/selectors'
import { CalculateAdminAnticipationValues } from '../utils/CalculateAdminAnticipationValues'
import ConfirmRemoveDialog from '_/components/dialogs/ConfirmRemoveDialog'

const defaultToast = { isOpen: false, title: '', severity: '' }
const defaultErrorGetToast = {
  isOpen: true,
  title: 'Ocorreu um erro ao buscar as informações.',
  severity: 'error',
}
const defaultErrorPostToast = {
  isOpen: true,
  title: 'Ocorreu um erro ao realizar a operação.',
  severity: 'error',
}

const filterFields = {
  serviceOrderId: '',
  company: '',
  createdAt: '',
  paymentDate: '',
  providerValueTotal: '',
  status: '',
  ableToPay: '',
  hasIrrf: '',
  accountType: '',
}
const defaultPaymentState = {
  count: undefined,
  currentPage: 0,
  currentPageSize: 10,
  sort: {
    field: undefined,
    sort: undefined,
  },
  orderBy: '',
  filters: {},
  hideFilters: false,
  name: undefined,
  next: undefined,
  previous: undefined,
  results: [],
}

const defaultDialog = {
  isOpen: false,
  icon: '',
  type: '',
  subject: '',
  description: '',
}

const defaultSuccessToast = {
  isOpen: true,
  severity: 'success',
  title: 'Ação executada com sucesso.',
}

const NON_APPROVED_ANTICIPATION_ERROR_MESSAGE =
  'Um ou mais pedidos selecionados ainda não foram aprovados. Aprove todos antes de solicitar seus pagamentos.'

const ALREADY_PAID_ANTICIPATION_ERROR_MESSAGE = 'Um ou mais pedidos selecionados já foram pagos.'

const ALREADY_PAID_OR_NON_APPROVED_ANTICIPATION_ERROR_MESSAGE =
  'Um ou mais pedidos selecionados já foram pagos ou ainda não foram aprovados.'

const HAVE_PAYING_ANTICIPATION_ERROR_MESSAGE =
  'Um ou mais pedidos selecionados já possuem pagamentos em processamento. Remova-os da seleção ou cancele seus lotes de pagamento antes de executar esta ação.'

const HAS_NON_PAID_ANTICIPATION_ERROR_MESSAGE =
  'Um ou mais pedidos selecionados ainda não estão pagos.'

const PaymentAdvanceTable = props => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const financeGenericParameters = useSelector(financeGenericParametersSelector)

  const showSuccessToast = props.location.state?.showSuccessToast || false

  const [toggleMenu, setToggleMenu] = useState('default')
  // const [datetimePayment, setDatetimePayment] = useState(moment())
  const [selectedItems, setSelectedItems] = useState([])
  const [budgetInstallments, setBudgetInstallments] = useState([])
  const [confirmationItemsIds, setConfirmationItemsIds] = useState([])
  const [confirmationSelectedItems, setConfirmationSelectedItems] = useState([])
  const [selectedPayments, setSelectedPayments] = useState([])
  const [dialog, setDialog] = useState(defaultDialog)
  const [isFloatingMenuOpen, setIsFloatingMenuOpen] = useState(false)
  const [dialogInfo, setDialogInfo] = useState()
  const [toastInfo, setToastInfo] = useState(showSuccessToast ? defaultSuccessToast : defaultToast)

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)

  const [filters, setFilters] = useState(filterFields)

  const [payments, setPayments] = useState(defaultPaymentState)

  const [isLoadingGetAnticipations] = useFetchCall(GET_PAYMENT_ANTICIPATIONS_LIST.ACTION, () => {})
  const [isLoadingGetInstallments] = useFetchCall(GET_BUDGET_INSTALLMENTS.ACTION, () => {})
  const [isLoadingRemovePayments] = useFetchCall(REMOVE_ANTICIPATIONS_PAYMENTS.ACTION, () => {})

  const { isConfirmed } = useConfirm()

  const getAnticipations = async ({
    page = payments.currentPage + 1,
    pageSize = payments.currentPageSize,
    orderBy = payments.orderBy,
  } = {}) => {
    await Promise.resolve(
      dispatch(
        getPaymentAnticipationsList({
          pageSize,
          page,
          orderBy,
          ...filters,
        })
      )
    )
      .then(response =>
        setPayments(old => ({
          ...old,
          currentPageSize: pageSize,
          currentPage: page - 1,
          orderBy,
          ...response,
        }))
      )
      .catch(() => setToastInfo(defaultErrorGetToast))
  }

  const handleGetFinanceGenericParameters = useCallback(() => {
    if (!Object.keys(financeGenericParameters).length) {
      dispatch(getFinanceGenericParameters())
    }
  }, [financeGenericParameters])

  useEffect(() => {
    if (payments.results.length < 1) {
      getAnticipations().catch(() => {})
      handleGetFinanceGenericParameters()
    }
  }, [])

  const handleSuccessPayment = useCallback(
    (success, message) => {
      if (!success) {
        message
          ? setDialog({
              isOpen: true,
              icon: DangerIcon,
              type: 'error',
              subject: 'Erro',
              description: message,
            })
          : setToastInfo(defaultErrorPostToast)
        return
      }
      getAnticipations({ page: payments.currentPage + 1 }).catch(() => {})

      setToggleMenu('default')
      setSelectedItems([])
      setConfirmationSelectedItems([])
      setSelectedPayments([])
      setToastInfo(defaultSuccessToast)
    },
    [toggleMenu, selectedItems, toastInfo, confirmationSelectedItems, selectedPayments, dialog]
  )

  const handleFilters = () => setFilterDrawerOpen(!filterDrawerOpen)

  const handleSelectedPayments = useCallback(() => {
    if (selectedItems.length > 0) {
      const newArray = [...selectedPayments?.filter(item => selectedItems.includes(item.id))]

      payments?.results?.map(
        item =>
          selectedItems.includes(item.id) &&
          !selectedPayments.filter(payment => item.id === payment.id)[0] &&
          newArray.push(item)
      )

      setSelectedPayments(newArray)
    } else {
      setSelectedPayments([])
    }
  }, [selectedItems, selectedPayments, payments])

  useEffect(() => {
    handleSelectedPayments()
  }, [selectedItems])

  useEffect(() => {
    if (selectedItems.length === 0) {
      setSelectedPayments([])
    }
  }, [selectedItems])

  const onChangePageSize = useCallback(
    pageSize => {
      getAnticipations({ pageSize }).catch(() => {})
    },
    [filters, payments]
  )

  const onChangePage = useCallback(
    next => {
      getAnticipations({ page: next + 1 }).catch(() => {})
    },
    [filters, payments]
  )

  const onChangeSort = useCallback(
    order => {
      if (!order.length) return

      let { field, sort } = order[0]

      if (order[0].field === 'actions') {
        field = 'status'
      }
      let orderBy = humps.decamelize(`${sort === 'asc' ? '' : '-'}${field}`)
      getAnticipations({ orderBy }).catch(() => {})
    },
    [filters, payments]
  )

  const handleCellClick = useCallback(params => {
    if (params.field !== '__check__') {
      window.open(
        `${FINANCE_ROUTES.PAYMENT_ADVANCE}/${params.row.id}`,
        '_blank',
        'noopener,noreferrer'
      )
    }
  }, [])

  const renderPaymentFilter = useMemo(
    () => (
      <PaymentsFilter
        handleFilterDrawer={handleFilters}
        isOpen={filterDrawerOpen}
        setFilters={setFilters}
        filters={filters}
        getAnticipations={getAnticipations}
        filterFields={filterFields}
        open={filterDrawerOpen}
      />
    ),
    [filterDrawerOpen, filters]
  )

  const handleRemoveAnticipations = useCallback(async () => {
    setDialogInfo({ message: 'Você tem certeza que deseja executar esta ação?' })

    const confirmed = await isConfirmed()
    if (!confirmed) return

    const hasNonPaidAnticipations = selectedPayments.some(payment => payment.status !== 'paid')

    if (hasNonPaidAnticipations) {
      setToastInfo({
        isOpen: true,
        severity: 'error',
        title: HAS_NON_PAID_ANTICIPATION_ERROR_MESSAGE,
      })
      return
    }

    await dispatch(removeAnticipationsPayments({ anticipationsIds: selectedItems }))
      .then(() => handleSuccessPayment(true))
      .catch(error =>
        setToastInfo({
          isOpen: true,
          severity: 'error',
          title: error || 'Ocorreu um erro ao executar a ação.',
        })
      )
  }, [selectedPayments, selectedItems])

  const handleFloatingMenuClick = useCallback(() => {
    const hasNonApprovedAnticipation = selectedPayments.some(
      item => item.status !== 'approved' && item.status !== 'paid'
    )
    const hasPaidAnticipation = selectedPayments.some(item => item.status === 'paid')
    const hasPayingAnticipation = selectedPayments.some(
      item => item.status === 'approved' && item.activeBatchId
    )

    let errorMessage = ''

    if (hasPayingAnticipation) {
      errorMessage = HAVE_PAYING_ANTICIPATION_ERROR_MESSAGE
    } else if (hasNonApprovedAnticipation && hasPaidAnticipation) {
      errorMessage = ALREADY_PAID_OR_NON_APPROVED_ANTICIPATION_ERROR_MESSAGE
    } else if (hasNonApprovedAnticipation || hasPaidAnticipation) {
      errorMessage = hasNonApprovedAnticipation
        ? NON_APPROVED_ANTICIPATION_ERROR_MESSAGE
        : ALREADY_PAID_ANTICIPATION_ERROR_MESSAGE
    }

    if (errorMessage) {
      setToastInfo({
        isOpen: true,
        title: errorMessage,
        severity: 'error',
      })
      return
    }
    setIsFloatingMenuOpen(false)
    fetchBudgetInstallments()
  }, [selectedItems, confirmationSelectedItems, toggleMenu, selectedPayments, isFloatingMenuOpen])

  const fetchBudgetInstallments = async () => {
    const budgetsIds = formatArrayToString(selectedPayments.map(item => item.budgetId))
    const params = { budgetsIds }
    let confirmationSelectedItemsResult = []

    await dispatch(getBudgetInstallments(params))
      .then(res => {
        confirmationSelectedItemsResult = res?.results?.map(item => item.id)
        setConfirmationItemsIds(confirmationSelectedItemsResult)
        setBudgetInstallments(res?.results)
      })
      .catch(err => {
        console.warn(err)
        setToastInfo(defaultErrorGetToast)
      })

    setConfirmationSelectedItems(confirmationSelectedItemsResult)
    setToggleMenu('pay')
  }

  useEffect(() => {
    setIsFloatingMenuOpen(selectedItems.length !== 0 && toggleMenu === 'default')
  }, [selectedItems, toggleMenu])

  const itemsWithTaxes = useMemo(
    () => CalculateAdminAnticipationValues({ items: selectedPayments }),
    [selectedPayments]
  )

  return (
    <>
      <Loader
        hasBackdrop
        open={isLoadingGetInstallments || isLoadingRemovePayments}
        label="Aguarde..."
      />
      {renderPaymentFilter}
      {toggleMenu === 'default' ? (
        <Grid>
          <HeaderTitle title="Antecipação de recebíveis">
            <Button
              variant="ghost"
              startIcon={<FilterIcon color={Theme.Colors.Primary.Base} />}
              onClick={handleFilters}
            >
              Filtros
            </Button>
          </HeaderTitle>
          <Grid className={styles.tableContainer}>
            <Grid className={styles.mainTable}>
              <Datagrid
                loading={isLoadingGetAnticipations}
                rows={payments?.results}
                columns={TableColumns({ styles })}
                paginationMode="server"
                onPageChange={onChangePage}
                onPageSizeChange={onChangePageSize}
                onSortModelChange={onChangeSort}
                pageSize={payments.currentPageSize}
                rowCount={payments.count}
                page={payments.currentPage}
                checkboxSelection
                onCellClick={handleCellClick}
                // isRowSelectable={params => params?.row?.status !== 'approved'}
                onSelectionModelChange={setSelectedItems}
                keepNonExistentRowsSelected
                selectionModel={selectedItems}
                sx={{
                  fontSize: '1.4rem',
                  '& .MuiDataGrid-cell:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <PaymentConfirmation
          toggleMenu={() => setToggleMenu('default')}
          menuType={toggleMenu}
          selectedIds={confirmationItemsIds}
          items={budgetInstallments}
          handleSuccessPayment={handleSuccessPayment}
          fromAnticipations
        />
      )}
      {dialogInfo?.message && <ConfirmRemoveDialog message={dialogInfo.message} />}

      {toastInfo.isOpen && (
        <Toast
          draggable
          open={toastInfo.isOpen}
          autoHideDuration={6000}
          onClose={() => setToastInfo(defaultToast)}
        >
          <Alert
            severity={toastInfo.severity}
            title={toastInfo.title}
            onClose={() => setToastInfo(defaultToast)}
          />
        </Toast>
      )}
      {isFloatingMenuOpen && (
        <FloatingMenu
          selected={selectedItems.length}
          items={itemsWithTaxes}
          handleGoToConfirm={handleFloatingMenuClick}
          isCsRefera
          removePaymentAction={handleRemoveAnticipations}
        />
      )}
      {dialog.isOpen && (
        <Dialog
          open={dialog.isOpen}
          icon={dialog.icon}
          type={dialog.type}
          subject={dialog.subject}
          description={dialog.description}
          onApprove={() => setDialog(defaultDialog)}
        />
      )}
    </>
  )
}

export default PaymentAdvanceTable
